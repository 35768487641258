:root {
  --primary-light-hsl: 147, 50%, 91%;
  --primary-hsl: 147, 50%, 47%;
  --primary-dark-hsl: 147, 50%, 6%;
  --success-hsl: 100, 60%, 50%;
  --error-hsl: 0, 60%, 50%;
  height: 100%;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "Digital";
  src: local("digital-7"),
   url("./fonts/digital-7.ttf") format("truetype");
  font-weight: bold;
}

.font-face-digital {
  font-family: "Digital";
 }

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

.bg-darkgreen1 {
  background-color: #3B5D50;
}

.bg-darkgreen {
  background-color: #35736E;
}

.bg-offwhite {
  background-color: #F5F5F5;
}

.font-darkgreen {
  color: #3B5D50;
}

.font-darkgreen2 {
  color: #016E89;
}

.font-green {
  color: #3D766C;
}

.font-gray {
  color: #8B8CA7 !important;
}

.font-darkgray {
  color: #636A7E;
}

.font-white {
  color: #FFF;
}

.font-yellow {
  color: #F9BF29;
}

.font-blue {
  color: #00F0FF;
}

.font-weight-bold {
  font-weight: bold;
}

.font-success {
  color: #28a745!important
}

.padding0 {
  padding: 0 !important;
}

.padding0Mobile1 {
  padding: 0;
}

.p0 {
  padding: 0 !important;
}

.pv1 {
  padding: 1em;
}

.pv2 {
  padding: 2em;
}

.pv0h1 {
  padding: 0 1em;
}

.pv2h1 {
  padding: 2em 1em;
}

.pv2h0 {
  padding: 2em 0;
}

.pb2 {
  padding-bottom: 2em;
}

.pv4h1 {
  padding: 4em 1em;
}

.pl0 {
  padding-left: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.mv1 {
  margin: 1em;
}

.mv2 {
  margin: 2em;
}

.mv4 {
  margin: 4em;
}

.mv2h1 {
  margin: 2em 1em;
}

.mv4h1 {
  margin: 4em 1em;
}

.mv3h0 {
  margin: 3em 0;
}

.mb1 {
  margin-bottom: 1em;
}

.mb2 {
  margin-bottom: 2em;
}

.mb4 {
  margin-bottom: 4em;
}

.mt1 {
  margin-top: 1em;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mrhalf {
  margin-right: 0.5em;
}

.mr1 {
  margin-right: 1em;
}

.xltitlefs {
  font-size: 8em;
}

.bigtitlefs {
  font-size: 6em;
}

.titlefs {
  font-size: 4em;
}

.subtitlefs {
  font-size: 2em;
}

.fs1em {
  font-size: 1em;
}

.fs15em {
  font-size: 1.5em;
}

.fs2em {
  font-size: 2em;
}

.fs3em {
  font-size: 3em;
}

.fs12px {
  font-size: 12px;
}

.wordBreakAll {
  word-break: break-all;
}

.wordBreakWord {
  word-break: break-word;
}

.wordBreak {
  word-break: normal;
}

.anchorNoStyle {
  text-decoration: none;
}

.anchorNoStyle:hover {
  text-decoration: none;
}

.community:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05); 
}

.maincontainer {
  display: flex;
  flex-direction: column;
}

.darkgreen_container {
  --color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
}

.link_container {
  display: flex;
  justify-content: space-around;
}

.notificationContainer {
  background: rgba(249, 191, 41, 0.5);
  padding: 0.5em 2em;
}

.dc {
  display: flex;
  flex: 1;
}

.g2 {
  gap: 2em;
}

.dashboard-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    /* padding: 4em; */
}

.dashboard-container-no-pad {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.purple_footer_bg {
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1675064433/wlog_footer_jregb4.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.green_footer_bg {
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1675150945/green_footer_rmxnno.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.tree_rings_bg {
  /* background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674718622/distorted_rings_bg_cvbkyg.png"); */
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1675066385/wlog_bg_50_n7pc1a.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.main_green_gradient {
  background: linear-gradient(0.5turn, #35736E, #F9BF29, #FFF);
}

.main_green {
  background-color: #35736E;
}

.main_black {
  background-color: #000;
}

.purple_gradient_text {
  background: linear-gradient(0deg, #1A6BAC 0%, #993B96 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purple-solid-bg {
  background-color: #602B64;
}

.purple_gradient {
  background: linear-gradient(29.59deg, #1A6BAC 0%, #993B96 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  padding: 2em 1em;
}

.purple_modal_gradient {
  background: linear-gradient(180deg, #963D97 0%, #1F6AAC 100%) !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 2em 1em;
}

.purple_gradient_2 {
  background: linear-gradient(180deg, #38123B 0%, #0F1E33 100%), #23242F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.green_gradient_2 {
  background: linear-gradient(180deg, #30675D 0%, #F9BF29 238.94%), #23242F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.green_gradient {
  background: linear-gradient(29.59deg, #43c1b7 0%, #77DD73 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  padding: 2em 1em;
}

.w25h280 {
  min-height: 280px;
  width: 25%;
}

.w35h320 {
  min-height: 320px;
  width: 35%;
}

.forest_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674569351/first_section_bg_lxul37.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #141d32;
}

.green_tree_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 580px;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674704232/trees_bg_green_sy1sp7.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #141d32;
}

.purple_tree_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 580px;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674569351/first_section_bg_lxul37.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #141d32;
  padding: 0;
}

.purple_tree_section_nh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674569351/first_section_bg_lxul37.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #141d32;
  background-position: center;
  padding: 2em;
}

.green_wood_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674704233/wood_bg_green_paauko.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #141d32;
}

.purple_dots_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1689582585/wlog_dots_n2bann.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.purple_wood_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674569351/second_section_bg_mlizjk.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-color: #141d32;
}

.purple_wood_section_bg {
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1674569351/second_section_bg_mlizjk.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-color: #141d32;
}

.dark_green_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  --color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
}

.dark_green_section_bg {
  padding: 2em;
  --color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
}

.wood-bg {
  background-image: url("../public/wlog_burn transparent.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 540px;
  flex-direction: column;
  align-items: center;
}

.wood_img {
  width: 100%;
}

.desc-container {
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-center-css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-start-css {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-spacebetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-spacearound {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flex-row-spacearound-centersm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flex-col-spacearound-centersm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-start-with-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #c1c1c1;
}

.flex-start {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.flex-col-g6 {
  gap: 6em;
  display: flex;
  flex-direction: column;
}

.flex-center-g2 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 2em;
}

.bb {
  border-bottom: 1px solid #c1c1c1;
}

.container-br {
  border-radius:1em;
  flex-direction: row;
  /*border: 1em solid #f8f9fa;
  background: linear-gradient(#ffffff, #35736e) 50% 50%/calc(100% - 20px) calc(100% - 20px) no-repeat,
            linear-gradient(45deg, #ffffff 0%, transparent 100%);
  border-radius: 8px;
  padding: 14px;
  box-sizing: border-box; */
}

.dots-container {
  background-image: url("../public/dots.png");
  background-repeat: no-repeat;
  background-position: center;
}

.status-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.message-container {
  display: flex;
  flex-direction: column;
  border: 2px solid gray;
  width: 50%;
  border-radius: 0.5em;
  padding: 40px 30px;
  justify-content: center;
  align-items: center;
}

.whitebg {
  flex: 1;
  background-color: white;
}

.cyanbg {
  background-color: #4EBDC5;
}

.purplebg {
  background-color: #768DCB;
}

.pinkbg {
  background-color: #F89797;
}

.black_gradient_old {
  background: linear-gradient(180deg, #38123B 0%, #000 50%), #000;
}

.black_gradient {
  background: linear-gradient(180deg, rgba(96, 43, 100, 0.9) 0%, rgba(15, 10, 15, 0.225) 100%);
}

.green_bg {
  background-color: #35736E;
}

.footer {
  height: 72px;
}

.pct {
  text-align: center;
}

.table_purplelt_bg {
  background: #23242F;
  border: 1px solid #8B8CA7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table td.text {
    max-width: 177px;
}
.table td.text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.taStart {
  text-align: start;
}

.account_table td {
  text-align: left;
}

.community_img {
  object-fit: cover;
}

.largeImgCommunity {
  width: 320px;
  height: auto;
}

.imgSize100 {
  width: 100%;
}

.imgSize550 {
  width: 550px;
  height: auto;
}

.imgSize382 {
  width: 382px;
  height: auto;
}

.imgSize320 {
  width: 320px;
  height: auto;
}

.imgSize280 {
  width: 280px;
  height: auto;
}

.imgSize240 {
  width: 240px;
  height: auto;
}

.custodianAddTable {
  display: block;
}

.custodianAddDiv {
  display: none;
}

/**
  FAQ
**/

.divShowMobile {
  display: none;
}

.sidebar {
  display: block;
  max-width: 20%;
  min-height: 200px;
  height: 25vh;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
  padding: 1em;
}

.selectedFaq {
  font-size: 18px;
  font-weight: bold;
  color: #c1c1c1 !important;
}

.faq-wrapper {
  display: flex;
  justify-content: space-between;
}

.wlborder {
  border-left: 1px solid #fff;
}

/**
  FAQ
**/

@media screen and (max-width: 1520px) {

  .width70 {
    width: 90%;
  }

}

@media screen and (max-width: 1260px) {
  .titlefs {
    font-size: 3em;
  }

  .width60 {
    width: 60%;
  }

  .imgSize100 {
    width: 80%;
  }
}

@media screen and (max-width: 1280px) {
  .dashboard-container {
    padding: 1.5em;
  }

  .container {
    width: 90% !important;
  }

  .purple_wood_section {
    padding: 2em 0;
  }

  .width70 {
    width: 90%;
  }

  .xltitlefs {
    font-size: 6em;
  }

  .bigtitlefs {
    font-size: 4em;
  }

  .desc-container {
    width: 100%;
  }

  .w35h320 {
    width: 60%;
  }

  .imgSize240 {
    width: 180px;
    height: auto;
  }

  .subtitlefs {
    font-size: 1em;
  }

  .titlefs {
    font-size: 3em;
  }

  .width60 {
    width: 60%;
  }

  .imgSize100 {
    width: 80%;
  }

}

@media screen and (max-width: 820px) {
  .w25h280 {
    width: 30%;
  }
  
  .w35h320 {
    width: 60%;
  }

  .width60 {
    width: 90%;
  }

  .mv3h0 {
    margin: 2em 0;
  }

  .flex-row-spacearound {
    flex-direction: row;
    align-items: center;
  }

  .flex-row-spacearound-centersm {
    flex-direction: row;
    align-items: center;
  }

  .flex-row-spacebetween {
    flex-direction: column;
  }

  .wood_img {
    width: 320px;
  }

  .imgSize100 {
    width: 90%;
  }

  .imgSize240 {
    width: 180px;
    height: auto;
  }

  .largeImgCommunity {
    width: 220px;
    height: auto;
  }

  .purple_wood_section {
    padding: 0;
  }

  .subtitlefs {
    font-size: 1em;
  }

}

@media screen and (max-width: 720px) {
  .sidebar {
    display: none;
  }

  .divShowMobile {
    display: flex;
  }

  .width80 {
    width: 100%;
  }

  .width90 {
    width: 100%;
  }

  .mb4 {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 655px) {
  
  .width70 {
    width: 100%;
  }

  .subtitlefs {
    font-size: 1em;
  }

  .w25h280 {
    width: 90%;
  }
  
  .w35h320 {
    width: 100%;
  }

  .flex-row-spacearound {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-row-spacearound-centersm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imgSize100 {
    width: 80%;
  }

  .imgSize550 {
    width: 80%;
    height: auto;
  }
  
  .imgSize320 {
    width: 80%;
    height: auto;
  }

  .imgSize280 {
    width: 80%;
    height: auto;
  }

  .largeImgCommunity {
    width: 240px;
    height: auto;
  }
}


@media screen and (max-width: 480px) {
  .dashboard-container {
    padding: 1em;
  }

  .width40 {
    width: 90%;
  }
  
  .width50 {
    width: 90%;
  }

  .width60 {
    width: 90%;
  }

  .width70 {
    width: 100%;
  }

  .desc-container {
    width: 100%;
  }

  .xltitlefs {
    font-size: 4em;
  }

  .bigtitlefs {
    font-size: 4em;
  }

  .titlefs {
    font-size: 2em;
  }

  .subtitlefs {
    font-size: 1em;
  }

  .container-br {
    flex-direction: column;
  }

  .mv2 {
    margin: 0 6px;
  }

  .mv3h0 {
    margin: 2em 0;
  }

  .padding0Mobile1 {
    padding: 1em;
  }

  .pv2 {
    padding: 0;
  }

  .pv4h1 {
    padding: 2em;
  }

  /* .dashboard-container {
      background: linear-gradient(0.5turn, #35736E, #F9BF29, #FFF);
  } */

  .w25h280 {
    width: 90%;
  }
  
  .w35h320 {
    width: 100%;
  }

  .flex-row-spacearound {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-row-spacearound-centersm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wood_section {
    padding: 1em 0;
  }

  .purple_tree_section {
    padding: 0 2em;
  }

  .purple_wood_section {
    padding: 0;
  }

  .custodianAddTable {
    display: none;
  }

  .custodianAddDiv {
    display: block;
  }

  .wood_img {
    width: 280px;
  }

  .flex-row-spacebetween {
    flex-direction: column;
  }

  .imgSize100 {
    width: 80%;
  }

  .imgSize550 {
    width: 80%;
    height: auto;
  }
  
  .imgSize382 {
    width: 80%;
    height: auto;
  }

  .imgSize320 {
    width: 90%;
    height: auto;
  }

  .imgSize280 {
    width: 80%;
    height: auto;
  }

  .flex-center-g2 {
    flex-direction: column;
    gap: 1em;
  }

  .largeImgCommunity {
    width: 240px;
    height: auto;
  }

  .text-justify-bword {
    text-align: center;
  }
}


/**
  Modal instructional instructions
**/
ul {
  list-style: none;
}

ul li {
  padding-bottom: 20px;
  position:relative
}

ul li span {
  border-radius: 50%;
  border: 1px solid black;
  padding: 5px 10px;
  margin-right: 10px;
}
ul li span:before{
  content:'';
  position:absolute;
  border-left:1px solid black;
  left:14px;
  bottom:20%;
  height:60%
}

ul li:last-child span:before{
 content:none;
}
/* ul li:last-child{
  padding-bottom:0
} */

.input-container {
  gap: 1em;
  display: flex;
  flex-direction: column;
}

.instructional-input {
  border: 1px solid #FFF;
  border-radius: 1.2em;
  padding: 0.5em 2em;
  width: 75%;
}

.minHeight190 {
  min-height: 190px;
}

.purpleButton {
  color: #fff;
  padding: 0.5em 2.5em;
  background: #3B3C4E;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.greenButton {
  color: #fff;
  padding: 0.5em 2.5em;
  background: #F9BF29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.yellowButton {
  padding: 0.5em 2.5em;
  background-color: #F9BF29;
  border-radius: 18px;
  color: #fff;
  filter: drop-shadow(2px 4px 6px #c4c4c4);
}

.modal-content {
  background-color: #f8f8f9;
}

.modal-body {
  color: #616161;
}

.modal-footer {
  justify-content: center !important;
  flex-direction: column;
}

.flex-center-fullheight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wallet_container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  padding: 0 1em;
}

.wallet_container_disabled {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  background: rgb(241 239 239);
  padding: 0 1em;
}

.wallet_container:hover {
  border: 1px solid gray;
  margin: -1px;
}

.pointer {
  cursor: pointer;
}

.text-justify {
  text-align: justify;
  word-break: break-all;
}

.text-justify-bword {
  text-align: justify;
  word-break: break-word;
}

.g1 {
  gap: 1em;
}

.formlabel {
  text-align: start;
  margin-bottom: 0 !important;
  color: #616161;
  font-size: 14px;
}

.eMessage {
  color: red;
  font-size: 14px;
  text-align: start;
}

.loadingWallet {
  animation: text-color 2s ease-in-out infinite;
}

@keyframes text-color {
  0% {
    color: rgb(255, 252, 252, 1);
  }

  50% {
    color: rgba(255, 252, 252, .5);
  }

  100% {
    color: rgba(255, 252, 252, .1);
  }
}

.logImage {
  animation: 1s ease-out 0s 1 slideInLeft;
}

@keyframes slideInLeft {
  0% {
    transform: translateY(40%);
  }
  100% {
    transform: translateY(0);
  }
}


/**
  Table footer css
*/
.tableFooter {
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tf-button {
  background-color: transparent;
  border: none;
  padding: 7px 14px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.tf-activeButton {
  color: #7949FF;
}

.tf-inactiveButton {
  color: #FFF;
}
